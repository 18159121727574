<template>
  <div>
    <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
        fixed
        right
        top
        elevation="24"
    >
      <div v-html="snackText" class="text-h5" />
    </v-snackbar>

    <v-snackbar
        v-model="snackbarDownload"
        :color="snackbarDownloadColor"
        timeout="-1"
        fixed
        right
        top
        elevation="24"
    >
      <h4>{{ snackDownloadTitle }}</h4>
      <h5>{{ snackDownloadText }}</h5>
      <v-progress-linear indeterminate color="white darken-2" />
    </v-snackbar>
    <v-toolbar flat class="d-flex flex-row-reverse">
      <v-col class="col-print">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                class="mr-3"
                v-on="on"
                :disabled="disabledRelatorio"
            >
              <v-icon dark>mdi-printer</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="exportarAgendamentos()">
              <v-list-item-title>Relatório de Corte</v-list-item-title>
            </v-list-item>
            <v-list-item @click="relatorio_dia()">
              <v-list-item-title>Relatório do Dia</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-toolbar>
    <v-card class="p-6 mb-3">
      <v-card-text class="p-0">
        <v-row>
          <v-col class="col-12 col-xs-12 col-sm-2">
            <EmpresasSelect
                v-model="empresaSelected"
                obrigatorio
            />
          </v-col>
          <v-col>
            <ProducoesSelect
                v-model="producaoSelected"
                :empresa="empresaSelected"
                obrigatorio
            />
          </v-col>
          <v-col>
            <DatePicker label="Data" v-model="dataCarregamento"/>
          </v-col>

          <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1">
            <v-tooltip
                top
                v-model="pendingSearch"
                color="warning"
                max-width="139px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    max-width="20px"
                    outlined
                    block
                    color="info"
                    class="v-btn--height-fix"
                    @click="buscarPedidosAgendamentos"
                >
                  <v-icon dark>
                    mdi-cached
                  </v-icon>
                </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>

          <v-col>
            <v-btn
                outlined
                block
                class="v-btn--height-fix"
                :disabled="disabledBloqueioAgenda"
                @click="abrirDialogBloquearAgenda"
            >
              Bloquear Agenda
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                outlined
                block
                class="v-btn--height-fix w-25"
                :disabled="disabledDesbloqueioAgenda"
                @click="abrirDialogDesbloquearAgenda"
            >
              Desbloquear Agenda
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <h4 class="mt-5">Lista de Pedidos {{ $stringFormat.formatDate(dataCarregamento) }}</h4>
    <hr />
    <v-row>
      <!-- data-table -->
      <v-col class="px-0 px-md-3">
        <v-data-table
            dense
            show-expand
            item-key="row-key"
            :headers="headerPedidos"
            :items="dataPedidos"
            :loading="carregandoDataTable"
            loading-text="Carregando..."
            :items-per-page="-1"
            :hide-default-footer="true"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <template v-slot:progress>
            <v-progress-linear
                absolute
                indeterminate
                color="green"
            />
          </template>
          <template v-slot:[`item.status_pedido`]="{ item }">
              <span>
                <v-tooltip right v-if="statusListaPedidos[item.status_pedido]">
                  <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img :src="statusListaPedidos[item.status_pedido].icon" :alt="statusListaPedidos[item.status_pedido].text"/>
                  </span>
                  </template>
                  {{ statusListaPedidos[item.status_pedido].text }}
                </v-tooltip>
                <span v-else>-</span>
              </span>
          </template>

          <template v-slot:[`item.carga_do_dia`]="{ item }">
            <v-tooltip bottom v-if="item.carga_do_dia === 'Sim'">
              <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <img alt="" src="/media/svg/agenda-carregamento/carga-do-dia.svg">
            </span>
              </template>
              Carga do Dia
            </v-tooltip>
          </template>

          <template v-slot:[`item.cliente`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ $stringFormat.truncate($stringFormat.capitalize(item.cliente), 45) }}</span>
              </template>
              <span>{{ $stringFormat.capitalize(item.cliente) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.contato`]="{ item }">
            {{ formatarTelefone(item.contato) }}
          </template>

          <template v-slot:[`item.valor_pedido`]="{ item }">
            {{ $stringFormat.formatNumber(item.valor_pedido, 2) }}
          </template>

          <template v-slot:[`item.valor_frete`]="{ item }">
            {{ (!item.valor_frete_amaior > 0 ? item.valor_frete : item.valor_frete_amaior) | formatNumber }}
          </template>

          <template v-slot:[`item.data_alteracao_pedido`]="{ item }">
            <custom-label
                :color="getColor(item.data_alteracao_pedido ,item.data_agendamento )"
                :text="getAlteracaoPedido(getDatePedido(item.data_alteracao_pedido))"
            />
          </template>

          <template v-slot:[`item.paletizado`]="{ item }">
            <v-tooltip bottom v-if="item.paletizado === 'Sim'">
              <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <img alt="" src="/media/svg/agenda-carregamento/pedido-paletizado.svg">
              </span>
              </template>
              Pedido Paletizado
            </v-tooltip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="agendarCarregamentoDialog(item)">
              mdi-calendar
            </v-icon>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td colspan="6" class="py-2 align-top">
              <span class="d-flex"><strong class="pr-1">Total de Descontos (R$):</strong>{{$stringFormat.formatNumber(item.valor_descontos, 2)}}</span>
              <span class="d-flex"><strong class="pr-1">Natureza de Carregamento:</strong>{{item.natureza}}</span>
              <span class="d-flex"><strong class="pr-1">Tipo de Frete:</strong>{{item.tipo_frete}}</span>
              <span class="d-flex" v-if="!!item.observacao_pedido"><strong class="pr-1">Observação:</strong>{{item.observacao_pedido}}</span>
              <span class="d-flex"><strong class="pr-1">Vendedor (a):</strong>{{item.vendedor_nome}}</span>
              <span class="d-flex"><strong class="pr-1">Pedido criado em:</strong>{{item.data_criacao_pedido}}</span>
              <span class="d-flex"><strong class="pr-1">Última alteração em:</strong>{{item.data_alteracao_pedido}}</span>

            </td>
            <td class="py-2 align-top">
            <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-end">
              {{ $stringFormat.formatNumber(produto.preco_venda, 2)}}
            </span>
            </td>
            <td class="py-2 align-top">
            <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-end">
              {{ $stringFormat.formatNumber(produto.quantidade, 0, 4) }}
            </span>
            </td>
            <td></td>
            <td class="py-2 align-top" colspan="3">
            <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-start">
              {{ produto.produto }}
            </span>
            </td>
          </template>

          <template
              v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
          >
            <v-icon
                v-if="item.produtos"
                :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded }
            ]"
                @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>

          <template v-slot:[`item.quantidade`]="{ item }">
            {{ $stringFormat.formatNumber(somaM2Produtos(item), 0, 4) }}
          </template>

          <template v-slot:[`body.append`]="">
            <tr class="v-row-group__summary">
              <th colspan="7">Totais</th>
              <th class="text-right">{{ totalM2ProdutosPedidos ? $stringFormat.formatNumber(totalM2ProdutosPedidos, 0, 4) : '' }}</th>
              <th class="text-right" colspan="1">{{ dataPedidos.length > 0 ? $stringFormat.formatNumber(totalValorPedidos, 2) : '' }}</th>
              <th class="text-right">{{ dataPedidos.length > 0 ? $stringFormat.formatNumber(totalValorFretePedidos, 2) : '' }}</th>
              <th colspan="4"></th>
            </tr>
          </template>

          <template v-slot:no-data>
            {{ textoPadraoDataTable }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <h4 class="mt-5">Agendamentos {{ $stringFormat.formatDate(dataCarregamento) }}</h4>
    <hr />
    <v-row>
      <!-- data-table -->
      <v-col class="px-0 px-md-3">
        <v-data-table
            dense
            show-expand
            item-key="row-key"
            sort-by="hora_agendamento"
            :headers="headerAgendamentos"
            :items="dataAgendamentos"
            :loading="carregandoDataTable"
            loading-text="Carregando..."
            :items-per-page="-1"
            :hide-default-footer="true"
        >
          <template v-slot:progress>
            <v-progress-linear
                absolute
                indeterminate
                color="green"
            />
          </template>

          <template v-slot:[`item.horario_agendamento`]="{ item }">
            <v-icon v-if="item.reagendar" small color="red">
              mdi mdi-alert-circle
            </v-icon>
            {{ item.horario_agendamento }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img alt="" src="/media/svg/agenda-carregamento/aguardando-carregamento.svg" v-if="item.status === 'Aguardando carregamento'">
                    <img alt="" src="/media/svg/agenda-carregamento/carregando.svg" v-else-if="item.status === 'Em carregamento'">
                    <img alt="" src="/media/svg/agenda-carregamento/carregado.svg" v-else-if="item.status === 'Carregado'">
                    <img alt="" src="/media/svg/agenda-carregamento/bloqueado.svg" v-else-if="item.status === 'Bloqueado'">
                    <img alt="" src="/media/svg/agenda-carregamento/aguardando-pagamento.svg" v-else-if="item.status === 'Aguardando pagamento'">
                  </span>
                  </template>
                  {{ item.status }}
                </v-tooltip>
              </span>
          </template>

          <template v-slot:[`item.carga_do_dia`]="{ item }">
            <v-tooltip bottom v-if="item.carga_do_dia === 'Sim'">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <img alt="" src="/media/svg/agenda-carregamento/carga-do-dia.svg">
                </span>
              </template>
              Carga do Dia
            </v-tooltip>
          </template>

          <template v-slot:[`item.sequencia_ordem`]="{ item }">
            {{ item.sequencia_ordem }}
          </template>

          <template v-slot:[`item.cliente`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ $stringFormat.truncate($stringFormat.capitalize(item.cliente), 25) }}</span>
              </template>
              <span>{{ $stringFormat.capitalize(item.cliente) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.contato`]="{ item }">
            {{ formatarTelefone(item.contato) }}
          </template>

          <template v-slot:[`item.quantidade`]="{ item }">
            {{ $stringFormat.formatNumber(somaM2Produtos(item), 0, 4) }}
          </template>

          <template v-slot:[`item.valor_frete`]="{ item }">
            <template
                v-if="item.valor_frete > 0"
            >
              <v-chip
                  class="font-weight-bold"
                  :color="item.frete_liquidado ? 'green' : 'red'"
                  dark
                  :disabled="item.processandoPagamentoFrete"
                  label
                  small
              >
                {{ (!item.valor_frete_amaior > 0 ? item.valor_frete : item.valor_frete_amaior) | formatNumber }}
                <v-icon
                    v-if="$auth.hasPermission('agendamento-carregamento.confirmacao.pagamento.frete') && !item.frete_liquidado"
                    small
                    right
                    @click="openDialogPagarFrete(item)"
                >
                  mdi-swap-horizontal
                </v-icon>
              </v-chip>
            </template>
            <template v-else>
              {{ (!item.valor_frete_amaior > 0 ? item.valor_frete : item.valor_frete_amaior) | formatNumber }}
            </template>
          </template>

          <template v-slot:[`item.tipo_frete_pedido`]="{ item }">
            {{ formatTipoFretePedido(item) }}
          </template>

          <template v-slot:[`item.valor_pedido`]="{ item }">
            {{ $stringFormat.formatNumber(item.valor_pedido, 2) }}
          </template>

          <template v-slot:[`item.paletizado`]="{ item }">
              <span v-if="item.paletizado === 'Sim'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img alt="" src="/media/svg/agenda-carregamento/pedido-paletizado.svg">
                  </span>
                  </template>
                  Pedido Paletizado
                </v-tooltip>
              </span>
          </template>

          <template v-slot:[`item.data_alteracao_pedido`]="{ item }">
            <custom-label
                :color="getColor(getDatePedido(item.data_alteracao_pedido) ,item.data_agendamento )"
                :text="getAlteracaoPedido(getDatePedido(item.data_alteracao_pedido))"
            />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                v-if="item.reagendar"
                @click="reagendarAgendamentoDialog(item.agendamento_id)"
            >
              mdi-calendar
            </v-icon>
            <v-menu v-if="!item.reagendar" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list class="my-0" dense>
                <v-list-item  dense @click="boletosDiponiveisDialog(item)" v-if="item.qtde_boletos > 0">
                  <v-list-item-title>Imprimir Boletos</v-list-item-title>
                </v-list-item>
                <v-list-item  dense @click="alterarStatusDialog(item)" v-if="item.status !== 'Carregado'">
                  <v-list-item-title>Alterar Status</v-list-item-title>
                </v-list-item>
                <v-list-item  dense @click="reenviaNdd(item)" v-if="item.nota_fiscal && $auth.hasPermission('agendamento-carregamento.reenviar.ndd') && ['P','I','D','C'].includes(item.usu_tipfre) ">
                  <v-list-item-title>Reenviar NDD</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="$auth.hasPermission('agendamento-carregamento.liberacao.de.frete')"
                    :disabled="item.nota_fiscal || item.valor_frete === 0"
                    @click="abrirDialogLiberacaoFrete(item)"
                >
                  <v-list-item-title>Liberação de Frete</v-list-item-title>
                </v-list-item>

                <v-list-item  dense @click="reagendarAgendamentoDialog(
                    item.agendamento_id,
                    item.horario_agendamento,
                    item.status,
                    item.nota_fiscal,
                    item.ultima_atualizacao,
                    item.tipo_agendamento
                  )">
                  <v-list-item-title>Alterar Data/Horário</v-list-item-title>
                </v-list-item>
                <v-list-item  dense @click="abrirCanhotoNota(item)" :disabled="!item.url_canhoto">
                  <v-list-item-title>Canhoto da Nota</v-list-item-title>
                </v-list-item>
                <v-list-item  dense @click="exportarTermoConformidade(item.pedido)" v-if="item.nota_fiscal">
                  <v-list-item-title>Termo de Conformidade</v-list-item-title>
                </v-list-item>
                <v-list-item  dense @click="exportarOrdemEmbarque(item.pedido)" v-if="item.nota_fiscal">
                  <v-list-item-title>Ordem de Embarque</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="item.status_pedido !== 'Não Fechado' && item.status_pedido !== 'Bloqueado' && item.status_pedido !== 'Aguardando pagamento'"
                    dense
                    @click="visualizarNotaFiscal(item.pedido)"
                >
                  <v-list-item-title>Pedido/Nota</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="!item.nota_fiscal && item.tipo_agendamento !== 'Agendamento Automatico' && item.status!=='Carregado'"
                    dense
                    @click="removerAgendamentoDialog(item.agendamento_id)"
                >
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td colspan="10" class="py-2 align-top">
              <span class="d-flex"><strong class="pr-1">Total de Descontos (R$):</strong> {{$stringFormat.formatNumber(item.valor_descontos, 2)}}</span>
              <span class="d-flex"><strong class="pr-1">Natureza de Carregamento:</strong> {{item.natureza}}</span>
              <span class="d-flex"><strong class="pr-1">Tipo de Frete:</strong>{{item.tipo_frete}}</span>
              <span class="d-flex" v-if="!!item.observacao_pedido"><strong class="pr-1">Observação:</strong> {{item.observacao_pedido}}</span>
              <span class="d-flex"><strong class="pr-1">Vendedor (a):</strong>{{item.vendedor_nome}}</span>
              <span class="d-flex"><strong class="pr-1">Pedido criado em:</strong>{{item.data_criacao_pedido}}</span>
              <span class="d-flex"><strong class="pr-1">Última alteração em:</strong>{{item.data_alteracao_pedido}}</span>
            </td>
            <td class="py-2 align-top">
                <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-end">
                  {{ $stringFormat.formatNumber(produto.quantidade, 0, 4) }}
                </span>
            </td>
            <td class="py-2 align-top">
                <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-end">
                  {{ $stringFormat.formatNumber(produto.preco_venda, 2)}}
                </span>
            </td>
            <td></td>
            <td class="py-2 align-top" colspan="3">
                <span v-for="produto in item.produtos" v-bind:key="produto.nome" class="d-flex justify-start">
                  {{ produto.produto }}
                </span>
            </td>
          </template>

          <template
              v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
          >
            <v-icon
                v-if="item.produtos"
                :class="[
                  'v-data-table__expand-icon',
                  { 'v-data-table__expand-icon--active': isExpanded }
                ]"
                @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>

          <template v-slot:[`body.append`]="">
            <tr class="v-row-group__summary">
              <th colspan="11">Totais</th>
              <th class="text-right"><span>{{ totalM2ProdutosPedidosAgendados ? $stringFormat.formatM2(totalM2ProdutosPedidosAgendados) : '' }}</span></th>
              <th class="text-right"><span>{{ dataAgendamentos.length > 0 ? $stringFormat.formatNumber(totalValorPedidosAgendados, 2) : '' }}</span></th>
              <th class="text-right"><span>{{ dataAgendamentos.length > 0 ? $stringFormat.formatNumber(totalValorFretePedidosAgendados, 2) : '' }}</span></th>
              <th colspan="5"></th>
            </tr>
          </template>

          <template v-slot:no-data>
            {{ textoPadraoDataTable }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-row>
        <v-col class="col-sm-12 col-md-8 col-lg-9"></v-col>
        <v-col class="col-sm-12 col-md-4 col-lg-3">
          <v-list dense>
            <v-list-item v-if="totalGeralM2DePedidos || totalGeralM2DePedidos || (dataAgendamentos.length > 0 || dataPedidos.length > 0)">
              <v-list-item-content class="p-1">
                <v-list-item-title><h3 class="m-0 text-center">Total geral</h3></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="totalGeralM2DePedidos">
              <v-list-item-content class="p-1">
                <v-list-item-title><h6 class="m-0">Qtde (m²)</h6></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <h4 class="m-0">{{ $stringFormat.formatM2(totalGeralM2DePedidos) }}</h4>
              </v-list-item-action>
            </v-list-item>

            <v-divider class="mt-0" v-if="totalGeralM2DePedidos"></v-divider>

            <v-list-item v-if="totalGeralM2DePedidos">
              <v-list-item-content class="p-1">
                <v-list-item-title><h6 class="m-0">Pedidos (R$)</h6></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <h4 class="m-0">{{ $stringFormat.formatNumber(totalGeralValorPedidos, 2) }}</h4>
              </v-list-item-action>
            </v-list-item>

            <v-divider class="mt-0" v-if="totalGeralM2DePedidos"></v-divider>

            <v-list-item v-if="dataAgendamentos.length > 0 || dataPedidos.length > 0">
              <v-list-item-content class="p-1">
                <v-list-item-title><h6 class="m-0">Frete (R$)</h6></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <h4 class="m-0">{{ $stringFormat.formatNumber(totalGeralValorFreteDePedidos, 2) }}</h4>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogLiberacaoFrete" max-width="300">
      <v-card :disabled="processingLiberacaoFrete">
        <v-card-title>
          Liberação de Frete
          <v-spacer />
          <v-btn icon>
            <v-icon @click="fecharDialogLiberacaoFrete">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <my-currency
                  v-model="vlrfre"
                  readonly
                  label="Valor do Frete"
                  @input="errorsLiberacaoFrete['usu_vlrflb']=null"
              />
            </v-col>
            <v-col cols="12">
              <my-currency
                  v-model="usu_vlrflb"
                  label="Valor do Frete Negociado"
                  clearable
                  :error-messages="errorsLiberacaoFrete['usu_vlrflb']?errorsLiberacaoFrete['usu_vlrflb']:''"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="primary"
              text
              @click="fecharDialogLiberacaoFrete"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              depressed
              :loading="processingLiberacaoFrete"
              @click="salvarDialogLiberacaoFrete"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAgendarCarregamento" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Agendar Horário de Carregamento</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-5">
              <v-col>
                <v-text-field
                    v-model="dataCarregamento"
                    label="Data Carregamento"
                    type="date"
                    dense
                    outlined
                    @change="buscarPedidosAgendamentos"
                    disabled
                />
              </v-col>
              <v-col>
                <v-select
                    v-model="horaCarregamento"
                    :items="horariosCarregamento"
                    item-text="horario"
                    item-value="horario"
                    label="Horário"
                    dense
                    outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="fecharDialogAgendarCarregamento"
          >
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="agendarCarregamento" :disabled="horaCarregamento === ''">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlterarStatus" transition="dialog-bottom-transition" max-width="600">
      <v-card :disabled="disabledAlterarStatusCarregamento" :loading="disabledAlterarStatusCarregamento">
        <v-toolbar color="info" dark>
          <span class="headline">Alterar Status do Carregamento</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                    v-model="statusCarregamento"
                    :items="statusCarregamentoOptionsNew"
                    item-text="status"
                    item-value="id"
                    label="Status Carregamento"
                    class="mt-5"
                    dense
                    outlined
                    @change="alterarStatusCarregamento"
                />
              </v-col>
              <v-col v-if="statusCarregado" cols="12">
                <v-text-field
                    v-model="horaCarregamentoSaida"
                    label="Horário da Saída"
                    type="time"
                    dense
                    outlined
                />
              </v-col>
              <v-col v-if="statusEmCarregamento" cols="12">
                <v-text-field
                    v-model="horaChegadaCaminhao"
                    label="Horário chegada Caminhão"
                    type="time"
                    dense
                    outlined
                    @change="validarHorarioMaior"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="dialogAlterarStatus = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="atualizarStatusAgendamento"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPagarFrete" transition="dialog-bottom-transition" max-width="600">
      <v-card :disabled="dialogPagarFreteProcessing" :loading="dialogPagarFreteProcessing">
        <v-toolbar color="info" dark>
          <span class="headline">Liberação de Saldo de frete</span>
        </v-toolbar>
        <v-card-text class="text-h5 mt-3">
          Tem certeza que deseja liberar o saldo de frete para a nota <strong>{{ this.dialogPagarFreteItem_nota_fiscal }}</strong>?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="dialogPagarFrete = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="salvarPagamentoFrete"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBloquearAgenda" transition="dialog-bottom-transition" max-width="700">
      <v-card>
        <v-toolbar color="info" dark>
          <v-container>
            <v-row class="d-flex align-items-center">
              <v-col cols="7">
                <span class="headline">Bloquear Agenda</span>
              </v-col>
              <v-col cols="5">
                <v-btn block outlined dense @click="adicionarHorarioBloqueio">
                  Adicionar faixa de Bloqueio
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-spacer class="mt-5" />
            <v-row v-for="(horario, i) in horariosBloqueio" :key="i">
              <v-col cols="4">
                <v-text-field
                    v-model="horario.dataBloqueio"
                    label="Data"
                    type="date"
                    dense
                    outlined
                    @change="buscarHorariosBloqueios"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                    v-model="horario.horarioInicioBloqueio"
                    :items="horariosBloqueioOptions"
                    item-text="horario"
                    item-value="horario"
                    label="Hora Início"
                    dense
                    outlined
                />
              </v-col>
              <v-col cols="3">
                <v-select
                    v-model="horario.horarioFimBloqueio"
                    :items="horariosBloqueioOptions"
                    item-text="horario"
                    item-value="horario"
                    label="Hora Fim"
                    dense
                    outlined
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                    class="bg-danger text-white"
                    block
                    @click="removerHorarioBloqueio"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="motivoBloqueioAgenda"
                    outlined
                    label="Motivo"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="fecharDialogBloquearAgenda"
          >
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmarBloqueioAgenda">
            Bloquear Agenda
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDesbloquearAgenda" transition="dialog-bottom-transition" max-width="700">
      <v-card>
        <v-toolbar color="info" dark>
          <v-container>
            <span class="headline">Desbloquear Agenda</span>
          </v-container>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-5">
              <v-col>
                <v-data-table
                    dense
                    v-model="bloqueiosSelecionados"
                    :headers="headerDesbloqueios"
                    :hide-default-footer="true"
                    :items="dataBloqueios"
                    :disable-pagination="true"
                    item-key="id"
                    show-select
                    class="elevation-2"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                        absolute
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="motivoDesbloqueioAgenda"
                    outlined
                    label="Motivo"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="fecharDialogDesbloquearAgenda"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="confirmarDesbloqueioAgenda"
          >
            Desbloquear Agenda
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarBloqueioAgenda" max-width="500px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Tem certeza que deseja bloquear a agenda?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-progress-circular v-if="carregandoDialog"
                               :size="30"
                               :width="2"
                               color="white"
                               indeterminate
          />
          <v-btn v-if="carregandoDialog === false"
                 color="white"
                 text
                 outlined
                 large
                 @click="dialogConfirmarBloqueioAgenda = false"
          >
            Não
          </v-btn>
          <v-btn color="white" text outlined large @click="bloquearAgenda" v-if="carregandoDialog === false">
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarDesbloqueioAgenda" max-width="500px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Tem certeza que deseja desbloquear a agenda?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-progress-circular
              v-if="carregandoDialog"
              :size="30"
              :width="2"
              color="white"
              indeterminate
          />
          <v-btn v-if="carregandoDialog === false"
                 color="white"
                 text
                 outlined
                 large
                 @click="dialogConfirmarDesbloqueioAgenda = false"
          >
            Não
          </v-btn>
          <v-btn color="white" text outlined large @click="desbloquearAgenda" v-if="carregandoDialog === false">
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPreencherTodosCampos" max-width="500px">
      <v-card class="bg-warning text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Necessário preencher todos os campos!
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="white"
              text
              outlined
              large
              @click="dialogPreencherTodosCampos = false"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHorarioChegadaCaminhao" max-width="700px">
      <v-card class="bg-warning text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Horário informado deve ser maior ou igual ao horário do agendamento.
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="white"
              text
              outlined
              large
              @click="dialogHorarioChegadaCaminhao = false"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReagendarCarregamento" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Alterar Data/Horário de Carregamento</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-5">
              <v-col>
                <DatePicker
                    :disabled="disabledDataReagendamento"
                    v-model="dataReagendamento"
                    label="Data"
                    :min="dataReagendamento"
                />
              </v-col>
              <v-col>
                <v-select
                    v-model="horaReagendamentoCarregamento"
                    :items="horariosCarregamento"
                    item-text="horario"
                    item-value="horario"
                    label="Horário"
                    dense
                    outlined
                    :disabled="disabledHoraReagendamento"
                />
              </v-col>
            </v-row>
            <v-row v-if="mostrarMensagemDataHoraReagendamento">
              <v-col>
                <v-icon small color="red">
                  mdi mdi-alert-circle
                </v-icon>
                {{ mensagemDataHoraReagendamento }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="dialogReagendarCarregamento = false"
          >
            Cancelar
          </v-btn>
          <v-btn :disabled="disabledBotaoReagendarAgendamento" color="blue darken-1" text @click="reagendarAgendamento">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarRemocaoAgendamento" max-width="600px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Tem certeza que deseja remover esse agendamento?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="carregandoDialog === false"
                 color="white"
                 text
                 outlined
                 large
                 @click="dialogConfirmarRemocaoAgendamento = false"
          >
            Não
          </v-btn>
          <v-btn color="white" text outlined large @click="removerAgendamento" v-if="carregandoDialog === false">
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBoletosDisponiveis" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Boletos</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-container>
                  <v-data-table
                      dense
                      :headers="headerBoletosDisponiveis"
                      :items="boletosDisponiveis"
                      :hide-default-footer="true"
                      :loading="loadingBoletosDisponiveis"
                      loading-text="Carregando boletos"
                      disable-pagination
                  >
                    <template v-slot:progress>
                      <v-progress-linear
                          absolute
                          indeterminate
                          color="green"
                      ></v-progress-linear>
                    </template>
                    <template v-slot:[`item.vencimento`]="{ item }">
                      {{ $stringFormat.dateISOToBRL(item.vencimento, true) }}
                    </template>
                    <template v-slot:[`item.valor`]="{ item }">
                      R$ {{ $stringFormat.formatNumber(item.valor) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="imprimirBoleto(item)">
                        mdi-printer
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="dialogBoletosDisponiveis = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOrdemEmbarque" transition="dialog-bottom-transition" max-width="800">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Ordem de Embarque</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-container>
                  <v-data-table
                      v-model="pedidosSelecionadosOrdemEmbarque"
                      item-key="pedido"
                      show-select
                      :headers="headerOrdemEmbarque"
                      :items="pedidosOrdemEmbarque"
                      :hide-default-footer="true"
                  >
                    <template v-slot:[`header.data-table-select`]></template>
                    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                      <v-simple-checkbox
                          :value="isSelected"
                          :readonly="item.disabled"
                          :disabled="item.disabled"
                          @input="select($event)"
                      />
                    </template>
                  </v-data-table>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogOrdemEmbarque = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="gerarOrdemEmbarquePedidos">
            Gerar Ordem de Embarque
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reenviaNddDialog" hide-overlay persistent width="300">
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Enviando
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <custom-snackbar ref="snackbar" />
  </div>
</template>

<style scoped>
.col-print {
  max-width: max-content;
}

.v-btn--height-fix {
  height: 40px !important;
}

.v-data-table input,
.v-data-table button {
  height: 32px !important;
}

.v-data-table .row {
  align-items: baseline;
}

.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
</style>

<script>
import _ from 'lodash'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import MyCurrency from '@/view/components/MyCurrency.vue'
import StorageService from '@/core/services/storage.service'

export default {
  components: { MyCurrency },
  data () {
    const dataCarregamento = this.$route.query.data_carregamento ? this.$route.query.data_carregamento : this.$date.getISOToday()

    return {
      snackbar: false,
      snackText: '',
      snackbarColor: 'success',
      snackbarDownload: false,
      snackbarDownloadColor: 'success',
      snackDownloadTitle: '',
      snackDownloadText: '',
      pendingSearch: false,
      // início bloco liberação de frete
      processingLiberacaoFrete: false,
      vlrfre: null,
      usu_vlrflb: null,
      errorsLiberacaoFrete: [],
      idLiberacaoFrete: -1,
      dialogLiberacaoFrete: false,
      // término bloco liberação de frete
      dialogAgendarCarregamento: false,
      dialogAlterarStatus: false,
      dialogPagarFrete: false,
      dialogPagarFreteProcessing: false,
      dialogPagarFreteItem: null,
      dialogPagarFreteItem_nota_fiscal: '',
      dialogBloquearAgenda: false,
      dialogDesbloquearAgenda: false,
      dialogConfirmarBloqueioAgenda: false,
      dialogConfirmarDesbloqueioAgenda: false,
      dialogPreencherTodosCampos: false,
      dialogHorarioChegadaCaminhao: false,
      dialogReagendarCarregamento: false,
      dialogConfirmarRemocaoAgendamento: false,
      dialogBoletosDisponiveis: false,
      dialogOrdemEmbarque: false,

      loadingBoletosDisponiveis: false,

      dataPedidos: [],
      dataAgendamentos: [],
      statusCarregamentoOptions: [
        { id: 1, status: 'Aguardando Carregamento' },
        { id: 3, status: 'Em carregamento' },
        { id: 4, status: 'Carregado' },
      ],
      statusCarregamentoOptionsNew: [],

      statusCarregado: false,
      horariosCarregamento: [],
      numeroPedidoAgendamento: 0,
      empresaSelected: 0,
      producaoSelected: 0,
      dataCarregamento: dataCarregamento,
      statusCarregamento: 0,
      horaCarregamentoSaida: '',
      notaFiscal: 0,
      horaChegadaCaminhao: '',
      horaCarregamento: '',
      agendamentoId: 0,
      sequencia_ordem: 1,
      disabledBloqueioAgenda: true,
      disabledDesbloqueioAgenda: true,
      disabledRelatorio: true,
      horariosBloqueioOptions: [],
      horariosBloqueio: [
        {
          dataBloqueio: '',
          horarioInicioBloqueio: 0,
          horarioFimBloqueio: 0,
        },
      ],
      motivoBloqueioAgenda: '',
      motivoDesbloqueioAgenda: '',
      bloqueiosSelecionados: [],
      dataBloqueios: [],
      boletosDisponiveis: [],
      statusEmCarregamento: false,
      horarioAgendamento: '',
      disabledAlterarStatusCarregamento: false,
      idPedidoReagendamento: 0,
      horaReagendamentoCarregamento: '',
      carregandoDataTable: false,
      textoPadraoDataTable: 'Informe uma Empresa, Produção e Data',
      carregandoDialog: false,
      carregamentoRemoverId: 0,
      disabledHoraReagendamento: false,
      disabledDataReagendamento: false,
      mostrarMensagemDataHoraReagendamento: false,
      mensagemDataHoraReagendamento: '',
      disabledBotaoReagendarAgendamento: false,
      dataReagendamento: '',
      dataDoPedido: '',
      ultimaAtualizacao: '',
      pedidosOrdemEmbarque: [],
      pedidosSelecionadosOrdemEmbarque: [],
      reenviaNddDialog: false,
      statusListaPedidos: {
        'Aberto Total': {
          icon: '/media/svg/agenda-carregamento/aguardando-carregamento.svg',
          text: 'Aguardando carregamento',
        },
        'Aberto Parcial': {
          icon: '/media/svg/agenda-carregamento/aguardando-carregamento.svg',
          text: 'Aguardando carregamento',
        },
        Suspenso: {
          icon: '/media/svg/agenda-carregamento/bloqueado.svg',
          text: 'Bloqueado',
        },
        Liquidado: {
          icon: '/media/svg/agenda-carregamento/aguardando-carregamento.svg',
          text: 'Aguardando carregamento',
        },
        Cancelado: {
          icon: '/media/svg/agenda-carregamento/bloqueado.svg',
          text: 'Bloqueado',
        },
        'Aguardando carregamento': {
          icon: '/media/svg/agenda-carregamento/aguardando-carregamento.svg',
          text: 'Aguardando carregamento',
        },
        'Em carregamento': {
          icon: '/media/svg/agenda-carregamento/carregando.svg',
          text: 'Em carregamento',
        },
        Carregado: {
          icon: '/media/svg/agenda-carregamento/carregado.svg',
          text: 'Carregado',
        },
        Bloqueado: {
          icon: '/media/svg/agenda-carregamento/bloqueado.svg',
          text: 'Bloqueado',
        },
        'Aguardando pagamento': {
          icon: '/media/svg/agenda-carregamento/aguardando-pagamento.svg',
          text: 'Aguardando pagamento',
        },
      },
      pagamentoNDD: null,
      baixaPagamentoNDD: null,
    }
  },

  computed: {
    urlApiV3 () {
      return process.env.VUE_APP_NEW_URL
    },
    headerPedidos () {
      return [
        { value: 'data-table-expand', align: 'end p-0', sortable: false, class: 'custom-table--header' },
        { text: 'Status', value: 'status_pedido', align: 'center', class: 'custom-table--header', sortable: false },
        { text: 'Pedido', value: 'pedido', class: 'custom-table--header' },
        { value: 'carga_do_dia', class: 'custom-table--header', sortable: false, align: 'center' },
        { text: 'Cliente', value: 'cliente', class: 'custom-table--header' },
        { text: 'Cidade', value: 'cidade', class: 'custom-table--header' },
        { text: 'Contato', value: 'contato', class: 'custom-table--header' },
        { text: 'Qtde (m²)', value: 'quantidade', align: 'end', class: 'custom-table--header' },
        { text: 'Valor (R$)', value: 'valor_pedido', align: 'end', class: 'custom-table--header' },
        { text: 'Frete (R$)', value: 'valor_frete', align: 'end', class: 'custom-table--header' },
        { value: 'paletizado', class: 'custom-table--header', sortable: false, align: 'center' },
        { text: 'Produtos', value: 'produto', align: 'center', class: 'custom-table--header' },
        { text: 'Última alteração', value: 'data_alteracao_pedido', class: 'custom-table--header' },
        { value: 'actions', align: ' end p-0', sortable: false, class: 'custom-table--header' },
      ]
    },

    headerAgendamentos () {
      return [
        { value: 'data-table-expand', align: 'end p-0', sortable: false, class: 'custom-table--header' },
        { text: 'Agenda', value: 'horario_agendamento', class: 'custom-table--header' },
        { text: 'Saída', value: 'horario_saida', class: 'custom-table--header' },
        { text: 'Status', value: 'status', align: 'center', class: 'custom-table--header', sortable: false },
        { text: 'Pedido', value: 'pedido', class: 'custom-table--header', align: 'end' },
        { value: 'carga_do_dia', class: 'custom-table--header', sortable: false, align: 'center' },
        { text: 'NF', value: 'nota_fiscal', class: 'custom-table--header', align: 'start' },
        { text: 'Sequencia', value: 'sequencia_ordem', class: 'custom-table--header', align: 'start' },
        { text: 'Cliente', value: 'cliente', class: 'custom-table--header' },
        { text: 'Cidade', value: 'cidade', class: 'custom-table--header' },
        { text: 'Contato', value: 'contato', class: 'custom-table--header' },
        { text: 'Qtde (m²)', value: 'quantidade', align: 'end', class: 'custom-table--header' },
        { text: 'Valor (R$)', value: 'valor_pedido', align: 'end', class: 'custom-table--header' },
        { text: 'Frete (R$)', value: 'valor_frete', align: 'end', class: 'custom-table--header' },
        { text: 'Tipo Frete', value: 'tipo_frete_pedido', align: 'center', class: 'custom-table--header' },
        { value: 'paletizado', class: 'custom-table--header', sortable: false, align: 'center' },
        { text: 'Produtos', value: 'produto', align: 'center', class: 'custom-table--header' },
        { text: 'Última alteração', value: 'data_alteracao_pedido', sortable: false, class: 'custom-table--header' },
        { value: 'actions', align: 'end p-0', sortable: false, class: 'custom-table--header' },
      ]
    },

    headerDesbloqueios () {
      return [
        { text: 'Início', value: 'horario_inicio', class: 'custom-table--header' },
        { text: 'Fim', value: 'horario_fim', class: 'custom-table--header' },
        { text: 'Motivo Bloqueio', value: 'motivo', class: 'custom-table--header' },
      ]
    },

    headerBoletosDisponiveis () {
      return [
        { text: 'Boleto', value: 'boleto', class: 'custom-table--header' },
        { text: 'Vencimento', value: 'vencimento', class: 'custom-table--header' },
        { text: 'Valor', value: 'valor', class: 'custom-table--header' },
        { text: '', value: 'actions', sortable: false, class: 'custom-table--header' },
      ]
    },

    headerOrdemEmbarque () {
      return [
        { text: 'Pedido', value: 'pedido', sortable: false },
        { text: 'Nota Fiscal', value: 'nota', sortable: false },
        { text: 'Cliente', value: 'cliente', sortable: false },
        { value: 'data-table-select', sortable: false },
      ]
    },

    totalGeralM2DePedidos () {
      return this.totalM2ProdutosPedidos + this.totalM2ProdutosPedidosAgendados
    },

    totalGeralValorPedidos () {
      return this.totalValorPedidos + this.totalValorPedidosAgendados
    },

    totalGeralValorFreteDePedidos () {
      return this.totalValorFretePedidos + this.totalValorFretePedidosAgendados
    },

    totalM2ProdutosPedidos () {
      return _.sumBy(this.dataPedidos, (obj) => _.sumBy(obj.produtos, function (p) {
        return parseFloat(p.quantidade)
      })) || 0
    },

    totalValorFretePedidos () {
      return _.sumBy(this.dataPedidos, 'valor_frete') || 0
    },

    totalValorPedidos () {
      return _.sumBy(this.dataPedidos, 'valor_pedido') || 0
    },

    totalM2ProdutosPedidosAgendados () {
      return _.sumBy(this.dataAgendamentos, (obj) => _.sumBy(obj.produtos, function (p) {
        return parseFloat(p.quantidade)
      })) || 0
    },

    totalValorPedidosAgendados () {
      return _.sumBy(this.dataAgendamentos, 'valor_pedido') || 0
    },

    totalValorFretePedidosAgendados () {
      return _.sumBy(this.dataAgendamentos, function (o) {
        return o.valor_frete_amaior <= 0 ? o.valor_frete : o.valor_frete_amaior
      }) || 0
    },
  },

  watch: {
    empresaSelected () {
      this.pendingSearch = true
    },

    dataCarregamento () {
      this.pendingSearch = true
    },

    producaoSelected () {
      this.pendingSearch = true
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Vendas', route: 'vendas' },
      { title: 'Faturamento', route: 'faturamento' },
      { title: 'Agenda de Carregamento' },
    ])

    this.initialize()
  },

  methods: {

    formatTipoFretePedido (item) {
      switch (item.usu_tipfre) {
        case 'C':
          return 'Destacado CIF'
        case 'D':
          return 'Rateado'
        case 'I':
          if (item.opetra === 5) {
            return 'NDD FOB'
          }

          return 'Repom FOB'
        case 'R':
          return 'Retirada'
        case 'F':
          return 'Pago na Entrega'
        case 'P':
          if (item.opetra === 5) {
            return 'NDD CIF'
          }

          return 'Repom CIF'
        default:
          return 'Indefinido'
      }
    },

    abrirCanhotoNota (item) {
      const file = item.url_canhoto.split('/')
      const url = process.env.VUE_APP_API_URL + '/fretes/routeasy/proof-of-delivery' +
          `/${this.empresaSelected}/${this.producaoSelected}/${item.pedido}/${file[file.length - 1]}` +
          `?token=${StorageService.getToken()}`

      const newWindow = window.open(url, '_blank')

      newWindow.focus()
    },

    initialize () {
      this.empresaSelected = parseInt(this.$route.query.empresa_id) || 0
      this.producaoSelected = parseInt(this.$route.query.producao_id) || 0
    },

    getDatePedido (data) {
      if (!data) {
        return ''
      }

      return data.split(' ')[0]
    },

    async buscarPedidosAgendamentos () {
      if (this.empresaSelected === 0 || this.producaoSelected === 0) {
        return
      }

      this.pendingSearch = false
      this.carregandoDataTable = true
      this.dataAgendamentos = []
      this.dataPedidos = []

      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const dataCarregamento = this.dataCarregamento

      const parametros = `?empresa_id=${empresaId}&producao_id=${producaoId}&data=${dataCarregamento}`

      let pedidosSapiens = await ApiService.cancel('sapiens-pedido-carregamento').get(
        '/sapiens/pedido-carregamento',
        parametros,
      )

      let carregamentosAgricola = await ApiService.cancel('agendamento-carregamento').get(
        '/agendamento-carregamento/data-carregamento',
        parametros,
      )

      pedidosSapiens = pedidosSapiens.data.data
      carregamentosAgricola = carregamentosAgricola.data.data

      if (pedidosSapiens.length === 0 || carregamentosAgricola.length === 0) {
        this.textoPadraoDataTable = 'Nenhum registro encontrado.'
        this.disabledRelatorio = true
      }

      this.carregandoDataTable = false

      this.dataPedidos = pedidosSapiens.map((item, index) => ({ 'row-key': `${index}_${item.pedido}`, ...item }))

      this.dataAgendamentos = carregamentosAgricola.map((item, index) => (
        { 'row-key': `${index}_${item.pedido}`, processandoPagamentoFrete: false, ...item }
      ))

      if (this.dataAgendamentos.length > 0) {
        this.dataDoPedido = this.dataAgendamentos[0].data_agendamento
        this.disabledRelatorio = false
      }

      await this.buscarHorariosAgendamentos(parametros)
      await this.liberarBotaoBloqueioDesbloqueio()
    },

    async agendarCarregamento () {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      let statusId = 1
      const numeroPedido = this.numeroPedidoAgendamento
      const data = this.dataCarregamento
      const horarioAgendamento = this.horaCarregamento

      const pedidoDados = this.dataPedidos.filter((e) => e.pedido === numeroPedido)
      const bloqueioPedido = pedidoDados[0].bloqueio_pedido
      const codStatusPedido = pedidoDados[0].cod_status_pedido
      const transacaoPedido = pedidoDados[0].transacao_pedido
      const financeiroStatus = pedidoDados[0].financeiro_status
      const dataPedido = pedidoDados[0].data_prevista

      if (bloqueioPedido === 'S' || (codStatusPedido === '9' && transacaoPedido === '90000')) {
        statusId = 5
      } else if (financeiroStatus === '1' && transacaoPedido === '90110') {
        statusId = 6
      }

      const params = {
        empresa_id: empresaId,
        producao_id: producaoId,
        status_id: statusId,
        numero_pedido: numeroPedido,
        data,
        data_pedido: dataPedido,
        horario_agendamento: horarioAgendamento,
      }

      this.dialogAgendarCarregamento = false

      const agendamento = await ApiService.post('/agendamento-carregamento', params)

      let mensagem = ''

      this.snackbar = true

      if (agendamento.data.status === 202) {
        this.snackbarColor = 'warning'
        mensagem = agendamento.data.data
      } else {
        this.snackbarColor = 'success'
        mensagem = `Nº do Pedido ${numeroPedido} agendando com sucesso para às ${horarioAgendamento}`
      }

      this.snackText = mensagem
      await this.buscarPedidosAgendamentos()
      this.snackbar = false
      this.snackbarColor = 'success'
    },

    async atualizarStatusAgendamento () {
      const statusId = this.statusCarregamento
      const horarioSaida = this.horaCarregamentoSaida
      const horarioChegadaCaminhao = this.horaChegadaCaminhao
      const carregamentoId = this.carregamentoId
      const ultimaAtualizacao = this.ultimaAtualizacao

      this.disabledAlterarStatusCarregamento = true

      let mensagem = ''

      try {
        if (statusId === 4 && this.dialogPagarFreteItem.usu_tipfre !== 'R' && this.dialogPagarFreteItem.opetra === 5) {
          await ApiService.post(
            this.urlApiV3 + '/api/ndd/pagamento-imediato', this.pagamentoNDD,
          )

          await ApiService.post(
            this.urlApiV3 + '/api/ndd/write-off', this.baixaPagamentoNDD,
          )
        }

        const empresaId = this.empresaSelected
        const producaoId = this.producaoSelected
        const pedidoId = this.dialogPagarFreteItem.pedido

        const isPacote = await ApiService.get(
          '/agendamento-carregamento/is-pacote',
          `?empresa_id=${empresaId}&producao_id=${producaoId}&numero_pedido=${pedidoId}`,
        )
        const pedidos = isPacote.data.data
        let agendamento = ''

        if (pedidos.length > 1) {
          agendamento = await Promise.all(pedidos.map(async (pedido) => {
            const params = {
              status_id: statusId,
              horario_saida: horarioSaida,
              horario_chegada_caminhao: horarioChegadaCaminhao,
              ultimaAtualizacao,
              nota_fiscal: pedido.nota_fiscal,
            }
            return await ApiService.put(`/agendamento-carregamento/${pedido.agendamento_id}`, params)
          }))
        } else {
          const params = {
            status_id: statusId,
            horario_saida: horarioSaida,
            horario_chegada_caminhao: horarioChegadaCaminhao,
            ultimaAtualizacao,
            nota_fiscal: this.notaFiscal,
          }

          agendamento = await ApiService.put(`/agendamento-carregamento/${carregamentoId}`, params)
        }

        if (agendamento.data?.status === 202 || agendamento.status === 202) {
          this.snackbarColor = 'warning'

          if (agendamento.data.data === undefined) {
            if (Array.isArray(agendamento.data.errors)) {
              agendamento.data.errors.forEach((error) => {
                mensagem += '<p>' + error + '</p>'
              })
            } else {
              mensagem = agendamento.data.errors
            }
          } else {
            mensagem = agendamento.data.data
          }
        } else {
          this.snackbarColor = 'success'
          mensagem = 'Status Alterado'
        }
      } catch (error) {
        this.snackbarColor = 'warning'
        mensagem = 'Não foi possível alterar o status do agendamento. Entre em contato com nossa equipe de suporte.'
        console.log(error)
      }

      this.dialogPagarFreteItem = null
      this.dialogAlterarStatus = false
      this.statusCarregado = false
      this.statusCarregamento = ''
      this.horaCarregamentoSaida = ''
      this.horaChegadaCaminhao = ''
      this.snackbar = true
      this.snackText = mensagem
      await this.buscarPedidosAgendamentos()
      this.disabledAlterarStatusCarregamento = false
    },

    async removerAgendamento () {
      this.dialogConfirmarRemocaoAgendamento = false

      const agendamento = await ApiService.delete(`agendamento-carregamento/${this.carregamentoRemoverId}`)

      let mensagem = ''

      if (agendamento.data.status === 202) {
        this.snackbarColor = 'warning'
        mensagem = agendamento.data.data
      } else {
        this.snackbarColor = 'success'
        mensagem = 'Agendamento removido com sucesso'
      }

      this.snackbar = true
      this.snackText = mensagem
      await this.buscarPedidosAgendamentos()
      this.snackbar = false
      this.snackbarColor = 'success'
    },

    async reagendarAgendamento () {
      const id = this.idPedidoReagendamento
      const hora = this.horaReagendamentoCarregamento
      const data = this.dataReagendamento
      const ultimaAtualizacao = this.ultimaAtualizacao

      const agendamento = await ApiService.put(`agendamento-carregamento/${id}`, {
        horario_agendamento: hora,
        data,
        ultimaAtualizacao,
      })

      let mensagem = ''

      if (agendamento.data.status === 202) {
        this.snackbarColor = 'warning'
        mensagem = agendamento.data.data
      } else {
        this.snackbarColor = 'success'
        mensagem = `Horário reagendado para às ${hora} com sucesso`
      }

      this.dialogReagendarCarregamento = false
      this.horaReagendamentoCarregamento = ''
      this.snackbar = true
      this.snackText = mensagem
      await this.buscarPedidosAgendamentos()
      this.snackbar = false
      this.snackbarColor = 'success'
    },

    async bloquearAgenda () {
      const bloqueio = []
      const horarios = []

      for (const horario of this.horariosBloqueio) {
        horarios.push({
          data: horario.dataBloqueio,
          horario_inicio: horario.horarioInicioBloqueio,
          horario_fim: horario.horarioFimBloqueio,
        })
      }

      bloqueio.push({
        empresa_id: this.empresaSelected,
        producao_id: this.producaoSelected,
        horarios,
        motivo: this.motivoBloqueioAgenda,
      })

      this.carregandoDialog = true

      await ApiService.post('/horario-agendamento-bloqueio', bloqueio[0])

      this.dialogBloquearAgenda = false
      this.dialogConfirmarBloqueioAgenda = false
      this.motivoBloqueioAgenda = ''
      this.horariosBloqueio = []
      this.carregandoDialog = false

      this.horariosBloqueio.push({
        dataBloqueio: '',
        horarioInicioBloqueio: 0,
        horarioFimBloqueio: 0,
      })

      this.snackbar = true
      this.snackText = 'Agenda bloqueada com sucesso.'

      await this.buscarHorariosAgendamentos()
      await this.liberarBotaoBloqueioDesbloqueio()
      await this.buscarPedidosAgendamentos()
    },

    async desbloquearAgenda () {
      this.carregandoDialog = true

      for (const horario of this.bloqueiosSelecionados) {
        await ApiService.put(`/horario-agendamento-bloqueio/${horario.id}`, {
          motivo_desbloqueio: this.motivoDesbloqueioAgenda,
        })
      }

      this.carregandoDialog = true
      this.fecharDialogDesbloquearAgenda()
      this.snackbar = true
      this.snackText = 'Agenda desbloqueada com sucesso.'

      await this.buscarHorariosAgendamentos()
      await this.liberarBotaoBloqueioDesbloqueio()
    },

    async buscarHorariosAgendamentos () {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const dataCarregamento = this.dataCarregamento

      const parametros = `?empresa_id=${empresaId}&producao_id=${producaoId}&data=${dataCarregamento}`

      const horariosAgendamentos = await ApiService.get(
        '/horario-agendamento',
        parametros,
      )

      this.horariosCarregamento = horariosAgendamentos.data.data
    },

    async liberarBotaoBloqueioDesbloqueio () {
      this.disabledBloqueioAgenda = false
      this.disabledDesbloqueioAgenda = true

      const bloqueios = await this.buscarBloqueios()

      if (bloqueios.data.data.length > 0) {
        this.disabledDesbloqueioAgenda = false
        this.dataBloqueios = bloqueios.data.data
      }
    },

    async buscarBloqueios () {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const dataCarregamento = this.dataCarregamento

      return await ApiService.get(
        '/horario-agendamento-bloqueio/data-carregamento',
          `?empresa_id=${empresaId}&producao_id=${producaoId}&data=${dataCarregamento}`,
      )
    },

    async buscarHorariosBloqueios (data) {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      let dataCarregamento = data

      if (!dataCarregamento) {
        dataCarregamento = this.dataCarregamento
      }

      const horarios = await ApiService.get(
        '/horario-agendamento/bloqueio',
          `?empresa_id=${empresaId}&producao_id=${producaoId}&data=${dataCarregamento}`,
      )

      this.horariosBloqueioOptions = horarios.data.data
    },

    alterarStatusCarregamento (status) {
      this.statusCarregado = false
      this.statusEmCarregamento = false

      if (status === 4) {
        this.horaCarregamentoSaida = this.buscarHoraAtual()
        this.statusCarregado = true
      } else if (status === 3) {
        this.horaChegadaCaminhao = this.horarioAgendamento
        this.statusEmCarregamento = true
      }
    },

    adicionarHorarioBloqueio () {
      if (this.validarCampoFaixaHorario()) {
        this.dialogPreencherTodosCampos = true
      } else {
        this.horariosBloqueio.push({
          dataBloqueio: '',
          horarioInicioBloqueio: 0,
          horarioFimBloqueio: 0,
        })
      }
    },

    removerHorarioBloqueio (index) {
      const horarios = this.horariosBloqueio

      if (horarios.length > 1) {
        horarios.splice(index, 1)
      }
    },

    confirmarBloqueioAgenda () {
      this.carregandoDialog = false

      if (this.validarCampoFaixaHorario() || this.motivoBloqueioAgenda === '') {
        this.dialogPreencherTodosCampos = true
      } else {
        this.dialogConfirmarBloqueioAgenda = true
      }
    },

    validarCampoFaixaHorario () {
      const ultimoFaixaBloqueio = this.horariosBloqueio[
        this.horariosBloqueio.length - 1
      ]

      return (
        ultimoFaixaBloqueio.dataBloqueio === '' ||
          ultimoFaixaBloqueio.horarioFimBloqueio === 0 ||
          ultimoFaixaBloqueio.horarioInicioBloqueio === 0
      )
    },

    confirmarDesbloqueioAgenda () {
      if (
        this.bloqueiosSelecionados.length === 0 ||
          this.motivoDesbloqueioAgenda === ''
      ) {
        this.dialogPreencherTodosCampos = true
      } else {
        this.dialogConfirmarDesbloqueioAgenda = true
      }
    },

    formatarTelefone (telefone) {
      telefone = telefone.replace(/\D/g, '')
      telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2')
      telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2')
      return telefone
    },

    somaM2Produtos (item) {
      return _.sumBy(item.produtos, function (o) {
        return parseFloat(o.quantidade)
      }) || 0
    },

    getColor (dataAlteracaoPedido, dataAgendamento) {
      if (typeof dataAgendamento !== 'undefined' && typeof dataAlteracaoPedido !== 'undefined') {
        const dtAlteracaoPedido = this.$stringFormat.dateToISO(dataAlteracaoPedido)
        const dtAgendamento = this.$stringFormat.dateToISO(dataAgendamento)

        const dataAlteracao = new Date(dtAlteracaoPedido)
        const dataAgendamentoPedido = new Date(dtAgendamento)

        if (dataAgendamentoPedido <= dataAlteracao) {
          return 'danger'
        }
      }
    },

    getAlteracaoPedido (data) {
      return data
    },

    visualizarNotaFiscal (pedido) {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const baseUrl = `${window.location.origin}/vendas/faturamento/nota-fiscal/`
      const params = `?empresa_id=${empresaId}&producao_id=${producaoId}&pedido_id=${pedido}`
      window.open(`${baseUrl}${params}`, '_blank')
      localStorage.setItem('visualizando_pedido', 'true')
      this.verificarPaginaPedido()
    },

    buscarHoraAtual () {
      const data = new Date()
      const minutos = (data.getMinutes() < 10 ? '0' + data.getMinutes().toString() : data.getMinutes().toString())
      let hora = data.getHours()
      hora = ('0' + hora).slice(-2)
      return `${hora}:${minutos}`
    },

    validarHorarioMaior () {
      if (this.horaChegadaCaminhao < this.horarioAgendamento) {
        this.dialogHorarioChegadaCaminhao = true
        this.disabledAlterarStatusCarregamento = true
        return
      }

      this.dialogHorarioChegadaCaminhao = false
      this.disabledAlterarStatusCarregamento = false
    },

    verificarPaginaPedido () {
      const verificando = this

      if (localStorage.getItem('visualizando_pedido') === 'false') {
        this.buscarPedidosAgendamentos()
        localStorage.removeItem('visualizando_pedido')
        return false
      }

      setTimeout(function () {
        verificando.verificarPaginaPedido()
      }, 2000)
    },

    agendarCarregamentoDialog (pedido) {
      this.numeroPedidoAgendamento = pedido.pedido
      this.dialogAgendarCarregamento = true
      this.horaCarregamento = ''
    },

    getStatusWithoutOptionCarregado (status) {
      const newStatusCarregamentoOptions = status.filter((status) => (status.status !== 'Carregado'))
      // eslint-disable-next-line no-return-assign
      return this.statusCarregamentoOptionsNew = newStatusCarregamentoOptions
    },

    removerOpcaoStatusCarregadoSeNaoExistirNfOuNaoProcessouNdd (carregamento) {
      if (!carregamento.nota_fiscal || ((carregamento.opetra === 5 && ['P', 'I', 'D', 'C'].includes(carregamento.usu_tipfre)) && !carregamento.sitndd)) {
        return this.getStatusWithoutOptionCarregado(this.statusCarregamentoOptions)
      }

      this.statusCarregamentoOptionsNew = this.statusCarregamentoOptions
    },

    async abrirDialogLiberacaoFrete (item) {
      this.indexLiberacaoFrete = this.dataAgendamentos.indexOf(item)
      this.vlrfre = this.dataAgendamentos[this.indexLiberacaoFrete].valor_frete.valueOf()
      this.usu_vlrflb = this.dataAgendamentos[this.indexLiberacaoFrete].valor_frete_amaior.valueOf()
      this.dialogLiberacaoFrete = true
    },

    async fecharDialogLiberacaoFrete () {
      this.dialogLiberacaoFrete = false
      this.indexLiberacaoFrete = -1
      this.usu_vlrflb = null
    },

    async salvarDialogLiberacaoFrete () {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const pedidoId = this.dataAgendamentos[this.indexLiberacaoFrete].pedido

      this.processingLiberacaoFrete = true

      this.errorsLiberacaoFrete = []

      try {
        await ApiService.put(
            `/agendamento-carregamento/liberacao-frete?empresa_id=${empresaId}&producao_id=${producaoId}&pedido_id=${pedidoId}`,
            { usu_vlrflb: this.usu_vlrflb > 0 ? this.usu_vlrflb : null, vlrfre: this.vlrfre },
        )

        this.dataAgendamentos[this.indexLiberacaoFrete].valor_frete_amaior = this.usu_vlrflb.valueOf()

        this.$refs.snackbar.show(
          'Tudo certo!',
          'Operação realizada com sucesso',
          'success',
          '2000',
        )

        this.fecharDialogLiberacaoFrete()
      } catch (e) {
        if (e.response) {
          if (e.response.status === 422) {
            this.errorsLiberacaoFrete = e.response.data.errors

            this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Verifique os campos em vermelho e tente novamente',
              'danger',
              3000,
            )
          } else if (e.response.data.message) {
            this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              e.response.data.message,
              'danger',
            )
          } else {
            this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Tente novamente mais tarde',
              'danger',
            )
          }
        } else {
          this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      this.processingLiberacaoFrete = false
    },

    async alterarStatusDialog (carregamento) {
      this.pagamentoNDD = {
        subsidiary_id: (parseInt(this.empresaSelected) * 100) + parseInt(this.producaoSelected),
        invoice_number: carregamento.nota_fiscal,
        payment_type: 'ADT',
      }

      this.baixaPagamentoNDD = {
        companyId: this.empresaSelected,
        subsidiaryId: this.producaoSelected,
        invoiceNumber: carregamento.nota_fiscal,
        paymentType: 'ADT',
        userId: carregamento.user,
      }

      this.removerOpcaoStatusCarregadoSeNaoExistirNfOuNaoProcessouNdd(carregamento)
      const status = carregamento.status_id
      this.horarioAgendamento = carregamento.horario_agendamento
      this.ultimaAtualizacao = carregamento.ultima_atualizacao
      this.notaFiscal = carregamento.nota_fiscal

      if (status === 4 || status === 3) {
        const empresaId = this.empresaSelected
        const producaoId = this.producaoSelected
        const numeroPedido = carregamento.pedido

        const pedido = await ApiService.get(
          '/agendamento-carregamento/pedido',
          `?empresa_id=${empresaId}&producao_id=${producaoId}&pedido=${numeroPedido}`,
        )

        if (status === 4) {
          this.horaCarregamentoSaida = pedido.data.data[0].horario_saida
          this.statusCarregado = true
        } else {
          this.horaChegadaCaminhao =
              pedido.data.data[0].horario_chegada_caminhao

          this.statusEmCarregamento = true
        }
      } else {
        this.statusCarregado = false
        this.statusEmCarregamento = false
      }

      this.dialogPagarFreteItem = carregamento
      this.carregamentoId = carregamento.agendamento_id
      this.statusCarregamento = carregamento.status_id
      this.dialogAlterarStatus = true
    },

    async reenviaNdd (carregamento) {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const numNota = carregamento.nota_fiscal

      this.reenviaNddDialog = true

      const pedido = await ApiService.get(
        '/agendamento-carregamento/reenviar-ndd',
        `?empresa_id=${empresaId}&producao_id=${producaoId}&num_nota=${numNota}`,
      )
      this.reenviaNddDialog = false
      const erro = pedido.data.data.erro

      if (erro) {
        let mensagem = ''

        if (Array.isArray(pedido.data.data.retornoCIOT)) {
          pedido.data.data.retornoCIOT.forEach((error) => {
            if (error.tipo !== 'EMAIL REL.') {
              mensagem += '<p>' + error.mensagem + '</p>'
            }
          })
        } else if (pedido.data.data.retornoCIOT) {
          mensagem = pedido.data.data.retornoCIOT
        } else {
          mensagem = pedido.data.data.retorno
        }

        this.snackbar = true
        this.snackText = mensagem
        this.snackbarColor = 'warning'
      } else {
        this.snackbar = true
        this.snackText = 'Pedido reenviado com sucesso'
        this.snackbarColor = 'success'
      }
    },

    reagendarAgendamentoDialog (id, hora, status, nota, ultimaAtualizacao, tipoAgendamento) {
      this.disabledHoraReagendamento = false
      this.disabledDataReagendamento = false
      this.mostrarMensagemDataHoraReagendamento = false
      this.disabledBotaoReagendarAgendamento = false

      let mensagem = ''

      if (nota === null && tipoAgendamento === 'Agendamento Manual' && status !== 'Carregado') {
        this.disabledDataReagendamento = false
        this.disabledHoraReagendamento = false
        this.mostrarMensagemDataHoraReagendamento = false
      }

      if (nota === null && tipoAgendamento === 'Agendamento Automatico') {
        this.disabledDataReagendamento = true
        this.disabledHoraReagendamento = true
        this.mostrarMensagemDataHoraReagendamento = true
        mensagem = 'Não é possível alterar a Data e Hora pois a Nota não foi emitida.'
        this.mensagemDataHoraReagendamento = mensagem
      }

      if (status === 'Carregado') {
        this.disabledHoraReagendamento = true
        this.disabledDataReagendamento = true
        this.disabledBotaoReagendarAgendamento = true
        this.mostrarMensagemDataHoraReagendamento = true
        mensagem = 'Não é possível alterar a Data e Hora pois o Pedido já foi carregado.'
        this.mensagemDataHoraReagendamento = mensagem
      }

      this.idPedidoReagendamento = id
      this.horaReagendamentoCarregamento = hora
      this.dataReagendamento = this.$date.convertBRLToISO(this.dataDoPedido)
      this.ultimaAtualizacao = ultimaAtualizacao
      this.dialogReagendarCarregamento = true
    },

    removerAgendamentoDialog (id) {
      this.carregamentoRemoverId = id
      this.dialogConfirmarRemocaoAgendamento = true
    },

    boletosDiponiveisDialog (item) {
      this.fetchBoletosDisponiveis(item)
      this.loadingBoletosDisponiveis = true
      this.dialogBoletosDisponiveis = true
      this.boletosDisponiveis = []
    },

    abrirDialogBloquearAgenda () {
      this.dialogBloquearAgenda = true
    },

    abrirDialogDesbloquearAgenda () {
      this.dialogDesbloquearAgenda = true
    },

    fecharDialogBloquearAgenda () {
      this.dialogBloquearAgenda = false
    },

    fecharDialogDesbloquearAgenda () {
      this.dialogDesbloquearAgenda = false
      this.dialogConfirmarDesbloqueioAgenda = false
      this.bloqueiosSelecionados = []
      this.motivoDesbloqueioAgenda = ''
      this.buscarPedidosAgendamentos()
    },

    fecharDialogAgendarCarregamento () {
      this.dialogAgendarCarregamento = false
      this.horaCarregamento = ''
    },

    fetchBoletosDisponiveis (item) {
      const params = {
        empresa_id: this.empresaSelected,
        producao_id: this.producaoSelected,
        cliente_id: item.codigo_cliente,
        nota_fiscal: item.nota_fiscal,
      }

      ApiService.get('boleto/boletos-disponiveis', `?${this.$stringFormat.queryParams(params)}`)
        .then((res) => {
          this.boletosDisponiveis = res.data
          this.loadingBoletosDisponiveis = false
        })
    },

    imprimirBoleto (item) {
      window.open(process.env.VUE_APP_API_URL + `/boleto/imprimir?empresa_id=${item.empresa}&producao_id=${item.producao}&documento=${item.boleto}`, '_blank')
    },

    async exportarTermoConformidade (pedido) {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected

      const parametros = `?empresa_id=${empresaId}&producao_id=${producaoId}&pedido_id=${pedido}`

      const response = await ApiService.get(
        'sapiens/nota-fiscal-liberacao-colheita',
        parametros,
      )

      this.snackbarDownload = true
      this.snackbarDownloadColor = 'success'
      this.snackDownloadTitle = 'Gerando o arquivo'
      this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'

      const termoParams = {
        relatorio: 'termo-conformidade/termo-conformidade-agendamento',
        formato: 'pdf',
        arquivo: 'termo_de_conformidade',
        parametros: JSON.stringify({
          liberacao_colheita_id: response.data.data.toString(),
          pedido_id: pedido,
        }),
      }

      ApiService.post('relatorios/exportar-pdf', termoParams)
        .then((res) => {
          if (res.data.data.arquivo !== '') {
            window.open(res.data.data.arquivo, '_blank')
          }

          setTimeout(() => (this.snackbarDownload = false), 3000)
        })
        .catch((error) => {
          setTimeout(() => (this.snackbarDownload = false), 3000)
          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'

          this.snackDownloadText =
              'Não foi possíve gerar o arquivo, tente novamente mais tarde.'

          console.error('Erro: ', error)
        })
    },

    exportarOrdemEmbarque (pedido) {
      this.snackbarDownload = true
      this.snackbarDownloadColor = 'success'
      this.snackDownloadTitle = 'Verificando o Pedido'
      this.snackDownloadText = ''

      let parametros = `?empresa_id=${this.empresaSelected}`
      parametros += `&producao_id=${this.producaoSelected}`
      parametros += `&data=${this.dataCarregamento}`
      parametros += `&pedido=${pedido}`

      ApiService
        .get('/agendamento-carregamento/pedido-ordem-embarque', parametros)
        .then((res) => {
          const pedidos = res.data.data

          if (pedidos.length === 1) {
            this.gerarSequenciaEmbarque(pedido)
            this.gerarOrdemEmbarque(pedido)
            return
          }

          this.pedidosOrdemEmbarque = []
          this.pedidosSelecionadosOrdemEmbarque = []

          for (const p of pedidos) {
            this.pedidosOrdemEmbarque.push({
              pedido: p.pedido,
              nota: p.nota,
              cliente: p.cliente,
              sequencia_ordem: p.sequencia_ordem,
              disabled: p.pedido === pedido,
            })
          }

          this.pedidosSelecionadosOrdemEmbarque = [{ pedido }]

          this.snackbarDownload = false
          this.dialogOrdemEmbarque = true
        })
        .catch((error) => {
          console.error(error)

          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'
          this.snackDownloadText = 'Não foi possível gerar o arquivo, tente novamente mais tarde.'

          setTimeout(() => {
            this.snackbarDownload = false
          }, 3000)
        })
    },

    async gerarSequenciaEmbarque (agendaCarregamentos) {
      // retorna ultima sequencia do banco
      this.buscarPedidosAgendamentos()

      ApiService.put('/atualiza-sequencia-ordem', {
        empresa_id: this.empresaSelected,
        producao_id: this.producaoSelected,
        agenda_carregamento: [].concat(agendaCarregamentos),
      }).then(function (result) {
      })
    },

    gerarOrdemEmbarquePedidos () {
      const pedidos = this.pedidosSelecionadosOrdemEmbarque
      const pedidosIds = pedidos.map(function (pedido) {
        return pedido.pedido
      })
      this.gerarSequenciaEmbarque(pedidosIds)
      this.gerarOrdemEmbarque(pedidosIds.join(', '))
    },

    gerarOrdemEmbarque (pedidos) {
      this.dialogOrdemEmbarque = false
      this.snackbarDownload = true
      this.snackbarDownloadColor = 'success'
      this.snackDownloadTitle = 'Gerando o arquivo'
      this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'
      this.pedidosOrdemEmbarque = []
      this.pedidosSelecionadosOrdemEmbarque = []

      const params = {
        relatorio: 'faturamento/ordem-embarque',
        parametros: JSON.stringify({
          empresa_id: this.empresaSelected,
          producao_id: this.producaoSelected,
          pedidos,
        }),
      }

      ApiService
        .post('/relatorios/exportar-pdf', params)
        .then((res) => {
          if (res.data.data.arquivo !== '') {
            this.snackDownloadTitle = 'Arquivo gerado com sucesso'
            this.snackDownloadText = ''
            window.open(res.data.data.arquivo, '_blank')
          }

          setTimeout(() => {
            this.snackbarDownload = false
          }, 3000)
        })
        .catch((error) => {
          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'
          this.snackDownloadText = 'Não foi possível gerar o arquivo, tente novamente mais tarde.'

          setTimeout(() => {
            this.snackbarDownload = false
          }, 3000)

          console.error(error)
        })
    },

    async exportarAgendamentos () {
      const url = '/relatorios/exportar-pdf'
      const parametros = {
        empresa_id: this.empresaSelected,
        producao_id: this.producaoSelected,
        data: this.dataCarregamento,
      }

      const params = {
        relatorio: 'agenda-carregamento/agenda-carregamento',
        parametros: JSON.stringify(parametros),
      }

      this.snackbarDownload = true
      this.snackbarDownloadColor = 'success'
      this.snackDownloadTitle = 'Gerando o arquivo'
      this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'

      try {
        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.snackDownloadTitle = 'Arquivo gerado com sucesso'
            this.snackDownloadText = ''
            window.open(res.data.data.arquivo, '_blank')
          }

          setTimeout(() => (this.snackbarDownload = false), 3000)
        })
      } catch (error) {
        setTimeout(() => (this.snackbarDownload = false), 3000)
        this.snackbarDownloadColor = 'red'
        this.snackDownloadTitle = 'Error'

        this.snackDownloadText =
            'Não foi possível gerar o arquivo, tente novamente mais tarde.'

        console.error('Erro: ', error)
      }
    },

    async relatorio_dia () {
      const url = '/relatorios/exportar-pdf'
      const parametros = {
        empresa_id: this.empresaSelected,
        producao_id: this.producaoSelected,
        data: this.dataCarregamento,
      }

      const params = {
        relatorio: 'agenda-carregamento/agenda-carregamento-relatorio-dia',
        parametros: JSON.stringify(parametros),
      }

      this.snackbarDownload = true
      this.snackbarDownloadColor = 'success'
      this.snackDownloadTitle = 'Gerando o arquivo'
      this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'

      try {
        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.snackDownloadTitle = 'Arquivo gerado com sucesso'
            this.snackDownloadText = ''
            window.open(res.data.data.arquivo, '_blank')
          }

          setTimeout(() => (this.snackbarDownload = false), 3000)
        })
      } catch (error) {
        setTimeout(() => (this.snackbarDownload = false), 3000)
        this.snackbarDownloadColor = 'red'
        this.snackDownloadTitle = 'Error'

        this.snackDownloadText =
            'Não foi possível gerar o arquivo, tente novamente mais tarde.'

        console.error('Erro: ', error)
      }
    },
    openDialogPagarFrete (item) {
      this.dialogPagarFrete = true
      this.dialogPagarFreteItem = item
      this.dialogPagarFreteItem_nota_fiscal = item.nota_fiscal
    },
    closeDialogPagarFrete () {
      this.dialogPagarFrete = false
      this.dialogPagarFreteItem = null
    },
    async salvarPagamentoFrete () {
      const empresaId = this.empresaSelected
      const producaoId = this.producaoSelected
      const pedidoId = this.dialogPagarFreteItem.pedido
      const liquidado = true

      this.dataAgendamentos[this.dataAgendamentos.indexOf(this.dialogPagarFreteItem)].processandoPagamentoFrete = true

      try {
        if (this.dialogPagarFreteItem.usu_tipfre !== 'R' && this.dialogPagarFreteItem.opetra === 5) {
          await ApiService.post(
            this.urlApiV3 + '/api/ndd/pagamento-imediato', {
              subsidiary_id: (parseInt(empresaId) * 100) + parseInt(producaoId),
              invoice_number: this.dialogPagarFreteItem.nota_fiscal,
              payment_type: 'SLD',
            },
          )

          await ApiService.post(
            this.urlApiV3 + '/api/ndd/write-off', {
              companyId: empresaId,
              subsidiaryId: producaoId,
              invoiceNumber: this.dialogPagarFreteItem.nota_fiscal,
              paymentType: 'SLD',
              userId: this.dialogPagarFreteItem.user,
            },
          )
        }

        const isPacote = await ApiService.get(
          '/agendamento-carregamento/is-pacote',
          `?empresa_id=${empresaId}&producao_id=${producaoId}&numero_pedido=${pedidoId}`,
        )
        const pedidos = isPacote.data.data

        if (pedidos.length > 1) {
          pedidos.forEach((pedido) => {
            ApiService.post(
              '/agendamento-carregamento/pagamento-frete',
              {
                empresa_id: empresaId,
                producao_id: producaoId,
                pedido_id: pedido.pedido_id,
                liquidado: liquidado,
              },
            )
          })
        } else {
          await ApiService.post(
            '/agendamento-carregamento/pagamento-frete',
            {
              empresa_id: empresaId,
              producao_id: producaoId,
              pedido_id: pedidoId,
              liquidado: liquidado,
            },
          )
        }

        this.$refs.snackbar.show(
          'Tudo certo!',
          'Operação realizada com sucesso',
          'success',
          '2000',
        )
      } catch (e) {
        this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
      }

      this.closeDialogPagarFrete()
      await this.buscarPedidosAgendamentos()
    },
  },
}
</script>
